import React, { useContext } from 'react';
import { useImmerReducer } from "use-immer";
import { initialState } from 'store/initialState'
import { Context, reducer } from 'store/store'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from 'pages/login'
import FourOhFour from 'pages/fourohfour'
import Dashboard from 'pages/dashboard'
import './App.css';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { store } = useContext(Context)
  return <Route {...rest} render={(props) => (
    store.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
}

function App() {
  const token = sessionStorage.getItem('ss-token');
  if (token) {
    initialState.isAuthenticated = true
    initialState.jwt = token
  }

  const [store, dispatch] = useImmerReducer(reducer, initialState);

  return (
    <Context.Provider value={{ store, dispatch }}>

      <div>
        <Switch>
          <Route exact path="/" component={Login}/>
          <PrivateRoute exact path='/db' component={Dashboard} />
          <Route component={FourOhFour}/>
        </Switch>
      </div>
    </Context.Provider>
  );
}

export default App;
