import React, { useContext, useState } from 'react'
import { Context } from 'store/store'
import styles from './styles.module.sass'
import {Header, Icon, Image, Menu} from 'semantic-ui-react'
import logo from 'assets/images/logo-white.png'
import userAPI from 'api/user.js'

const SideNav = () => {

  const { dispatch } = useContext(Context)
  const [activeItem, setActiveItem] = useState('home')

  const handleItemClick = (e, { name }) => {
    setActiveItem(name)
  }

  const handleLogout = () => {
    userAPI.logout();
    dispatch({ type: 'logout' })
  }

  return (
    <div className={styles.navWrapper}>
      <div>
        <Header as='h2'>
          <Image spaced='right' verticalAlign='middle' size='mini' src={logo} />
          <span className={styles.navHeader}>Stepping Stones</span>
        </Header>
      </div>
      <Menu secondary vertical>
        <Menu.Item
          name='home'
          active={activeItem === 'home'}
          onClick={handleItemClick}
        >
          <div className={styles.navItem}>
            <Icon name='home' />
            Home
          </div>
        </Menu.Item>
        <Menu.Item
          name='logout'
          active={activeItem === 'logout'}
          onClick={handleLogout}
        >
          <div className={styles.navItem}>
            <Icon name='sign-out' />
            Logout
          </div>
        </Menu.Item>        
      </Menu>

    </div>
  )  
}

export default SideNav