import React from 'react';
import {initialState} from './initialState'
import {authReducer} from "reducers/authReducer";

export const reducer = (state, action) => {
    authReducer(state, action)

    switch (action.type) {
        case "reset":
            return initialState

        default:
            return
    }
}

export const Context = React.createContext()
