import axios from 'axios';

const userAPI = {
    login: async (input) => {
        try {
            const response = await axios.post('/be/api/user/login', input);
            sessionStorage.setItem('ss-token', response.data.jwt);
            return true;
        }
        catch (error) {
            return false;
        }
    },

    logout: () => {
        sessionStorage.removeItem('ss-token');
    }
}

export default userAPI;