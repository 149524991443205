import React, { useContext, useState } from 'react'
import { Context } from 'store/store'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import { Button, Container, Form, Grid, Header, Image, Segment, Message } from 'semantic-ui-react'
import logo from 'assets/images/logo.png'
import userApi from 'api/user'

const useLogin = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { dispatch } = useContext(Context)

  const handleSubmit = async (args) => {
    setIsLoading(true)
    const success = await userApi.login({ username, password })
    setIsLoading(false)
    if (success) {
      dispatch({ type: 'login' })
    } else {
      setErrorMessage('Invalid username or password!')
    }
  }

  const handleUsernameChange = event => {
    setUsername(_.get(event.target, 'value', '').trim())
  }

  const handlePasswordChange = event => {
    setPassword(_.get(event.target, 'value', '').trim())
  }

  return {
    errorMessage,
    handlePasswordChange,
    handleSubmit,
    handleUsernameChange,
    isLoading,
    password,
    username
  }
}

const Login = () => {
  const {errorMessage, handlePasswordChange, handleSubmit, handleUsernameChange, isLoading, password, username} = useLogin()
  const { store } = useContext(Context)

  if (store.isAuthenticated) {
    return <Redirect to='/db' />
  } else {
    return (
      <Container>
        <Grid textAlign='center' style={{ paddingTop: '100px' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='teal' textAlign='center'>
              <Image src={logo} /> Log-in to your account
            </Header>
            <Form onSubmit={handleSubmit} size='large'>
              <Segment>
                <Form.Input value={username} onChange={handleUsernameChange}
                  fluid icon='user'
                  iconPosition='left'
                  placeholder='Username'
                />
                <Form.Input
                  value={password} onChange={handlePasswordChange}
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                />

                <Button loading={isLoading} color='teal' fluid size='large'>
                  Login
                </Button>
              </Segment>
            </Form>
            {errorMessage &&
              <Message negative>
                <p>{errorMessage}</p>
              </Message>
            }
          </Grid.Column>
        </Grid>
      </Container>
    )
  }
}

export default Login