export const authReducer = (globalState, action) => {

    switch (action.type) {

        case 'login' :
            globalState.isAuthenticated = true;
            return

        case 'logout' :
            globalState.isAuthenticated = false;
            return

        default:
            return
    }
}